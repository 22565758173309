/*------------------------------------*\
    # BASE OVERRIDES
\*------------------------------------*/

/**
 * Add local base style overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

/* MMI-712 - Icon fix for iOS */
@media (max-width: 960px) {
    .toolbar__sorter .sorter-container .sorter__label:after {
        font-family: "FontAwesome" !important;
        transform: rotate(90deg);
        content: "\f105" !important;
        right: 10px !important;
        font-size: 23px !important;
        top: 5px !important;
    }
    .refine-filters__apply:after {
        font-family: "FontAwesome" !important;
        content: "\f00c" !important;
        font-size: 16px !important;
        margin-top: 3px !important;
    }
    .refine-filters__cancel:after {
        font-family: "FontAwesome" !important;
        content: "\f00d" !important;
        font-size: 16px !important;
        margin-left: 10px !important;
        margin-top: 3px !important;
    }
    .popup_wrapper .popup-close button:before,
    .modal-popup:not(.confirm) .action-close:after,
    .popup_content .popup-close button:before {
        background: url(/media/wysiwyg/Midas/iOS-icons/cross.png) no-repeat;
        width: 12px;
        height: 12px;
        background-size: contain;
        outline: 0;
        content: "" !important;
        font-size: 18px !important;
        display: inline-block;
    }
    .munro-accordion .accordion-title::after {
        font-family: "FontAwesome" !important;
        content: "\f067" !important;
        margin-top: 2px !important;
        line-height: 1.3em !important;
        font-size: 14px !important;
    }
    .munro-accordion .accordion-title.open::after {
        font-family: "FontAwesome" !important;
        content: "\f068" !important;
        margin-top: 2px !important;
        line-height: 1.3em !important;
        font-size: 14px !important;
    }
    .product-info__main .product-addto-links .towishlist:before {
        font-family: "munro-icons" !important;
        content: "\e901" !important;
        font-size: 18px !important;
        margin-right: 5px !important;
        vertical-align: text-bottom !important;
    }
    .fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store:before {
        background: url(/media/wysiwyg/Midas/iOS-icons/location-midas.png) no-repeat;
        content: "" !important;
        display: inline-block;
        background-size: contain;
        width: 24px;
        height: 24px;
        outline: 0;
    }
    .stockists-directory-list > div .stockists-directory__item .directory-item .directory-item__phone:before {
        font-family: "FontAwesome" !important;
        content: "\f095" !important;
        margin-right: 6px !important;
    }
    .stockists-directory-list > div .stockists-directory__item .directory-item address:before {
        background: url(/media/wysiwyg/Midas/iOS-icons/location-black.png) no-repeat;
        content: "" !important;
        display: inline-block;
        background-size: contain;
        width: 24px;
        height: 24px;
        outline: 0;
        margin-left: -30px !important;
    }
    .wishlist-index-index .product-tile__action--edit:before {
        line-height: 20px !important;
        margin-right: 3px !important;
        background: url(/media/wysiwyg/Midas/iOS-icons/edit.png) no-repeat;
        width: 12px;
        height: 12px;
        background-size: contain;
        outline: 0;
        content: "" !important;
        display: inline-block !important;
        vertical-align: baseline !important;
    }
    .wishlist-index-index .product-tile__action--delete:before {
        background: url(/media/wysiwyg/Midas/iOS-icons/cross.png) no-repeat;
        width: 12px;
        height: 10px;
        background-size: contain;
        outline: 0;
        content: "" !important;
        line-height: 20px !important;
        margin-right: 3px !important;
        display: inline-block !important;
        vertical-align: baseline !important;
    }
    #shopping-cart-table .cart__action--update::before {
        font-family: "FontAwesome" !important;
        content: "\f01e" !important;
        font-size: 18px !important;
    }
    .cart-page-header .cart__list--action::before {
        font-size: 20px !important;
        margin-right: 5px !important;
        font-family: "FontAwesome" !important;
        transform: rotate(180deg) !important;
        content: "\f105" !important;
    }
    .one-page-checkout__progress-bar .one-page-checkout__progress-step::before {
        font-family: "FontAwesome" !important;
        content: "\f141" !important;
        font-size: 22px !important;
    }
    .one-page-checkout__summary .items-in-cart .title::after {
        right: 10px !important;
        font-family: "FontAwesome" !important;
        transform: rotate(90deg) !important;
        content: "\f105" !important;
    }
    .one-page-checkout__summary .items-in-cart.active .title::after {
        right: 10px !important;
        font-family: "FontAwesome" !important;
        transform: rotate(-90deg) !important;
        content: "\f105" !important;
    }
    .cart-info-links .icon-local_shipping:before {
        font-family: "FontAwesome" !important;
        content: "\f0d1" !important;
        transform: rotateY(180deg);
        font-size: 18px !important;
        vertical-align: baseline;
    }
    .cart-info-links .icon-sync:before {
        font-family: "FontAwesome" !important;
        content: "\f021" !important;
        transform: rotate(90deg);
        font-size: 18px !important;
        vertical-align: baseline;
    }

    .cart-info-links .icon-local_phone:before {
        font-family: "FontAwesome" !important;
        content: "\f095" !important;
        font-size: 18px !important;
        vertical-align: baseline;
    }
}
