/*------------------------------------*\
    # LOCAL GENERAL SETTINGS
\*------------------------------------*/

/*------------------------------------*\
    ## COLOURS
\*------------------------------------*/

/**
 * All colours should be defined here; colour values should not be set in
 * partials.
 *
 * All colour variables should be prefixed with `$color-`. For example,
 * `$color-red`. Note the US spelling; this has been chosen to reduce
 * typographic errors in the `color` property.
 *
 * Colour variables can either be descriptive (eg `$color-red`) or
 * functional (eg `$color-text`).
 *
 * Tints or shades should use the parent variables with a `--` suffix. For
 * examples, `$color-red--light`.
 **/

$color-background: #fcfcfc;
$color-background-content: #fff;
$color-links: inherit;
$color-text: #333;
$color-shop-accent: #74a590;
$color-sale: #FF6B39;
$color-header-background: #F7F7F7;
$color-menu-items-border: #D3D3D3;
$color-navigation-border: #000;
$return-button-colour: $color-sale;
$color-swatch-border: $color-shop-accent;
$color-light-hover: #74A59033;
$address-validation-hover: $color-light-hover;

/*------------------------------------*\
    ## FONTS
\*------------------------------------*/

/**
 * All fonts should be defined here.
 *
 * All font-family variables should be prefixed with `$font-family-`.
 **/

//Fonts to be used are defined in the base theme

/* Stock Remaining Message */
.product__stock-message {
  color: $color-sale;
}
