.navigation-bar__group--account > li {
    width: 46px;
    vertical-align: top;
}

.siteHeaderBannerInnerContent .account-navigation {
    width: 328px;
    padding-top: 7px;
    max-width: 328px;
    text-align: right;
}

.navigation-bar__group--account .wishlist a:before,
.site-header__mobile-top .site-header__mobile-links li.wishlist a:before {
    background: url("/media/wysiwyg/Midas/icons/wishlist.png") no-repeat;
    width: 26px;
    height: 26px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    display: block;
}

.navigation-bar__group--account .wishlist .nav-wishlist-text,
.navigation-bar__group--account .minicart .minicart__label {
    display: none !important;
}

.navigation-bar__group--account .minicart a.minicart__summary:before,
.site-header__mobile-top .site-header__mobile-links li.minicart a:before {
    background: url("/media/wysiwyg/Midas/icons/bag.png") no-repeat;
    width: 26px;
    height: 26px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    display: block;
}

.navigation-bar__group--account .search-suggest-icon-wrapper .search-suggest-icon:before,
.site-header__mobile-top .mobile-header-search-toggle-container .mobile-header-search-toggle:before {
    background: url(/media/wysiwyg/Midas/icons/search.png) no-repeat;
    width: 26px;
    height: 26px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    display: block;
}

.siteHeaderBannerContent .siteHeaderBannerInnerContent .desktop-header-search-toggle-container {
    display: inline-block;
    padding-top: 3px;
}

.desktop-header-search-toggle-container .desktop-header-search-toggle {
    background: url(/media/wysiwyg/Midas/icons/search.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    outline: 0;
    display: block;
}

@media screen and (min-width: 960px) {
    .siteHeaderBannerInnerContent .site-header__logo-container {
        vertical-align: middle;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        position: absolute;
        text-align: center;
        max-width: 200px;
    }
    .siteHeaderBannerInnerContent .site-header__logo-container img {
        height: 40px;
    }
    .navigation-bar__group--account .search-suggest-icon-wrapper,
    .navigation-bar__group--account .search-suggest-icon {
        display: inline-block !important;
    }
    .navigation-bar__group--account .search-suggest-icon-wrapper {
        width: 30px !important;
    }
    .navigation-bar__group--account > li + li {
        padding-left: 20px !important;
    }
    .navigation-bar__group--account li a {
        font-size: 16px;
        color: #000;
        line-height: 1.5em;
    }
    .navigation-bar-scrolled .site-header__logo-container img {
        max-width: 122px;
    }
}
@media (max-width: 960px) {
    .site-header__mobile-top {
        padding: 23px 0px 0 0px;
    }
    .site-header__mobile-top .site-header__logo-container {
        vertical-align: middle;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        position: absolute;
        text-align: center;
        margin-top: 20px !important;
        width: 100px;
    }
    .site-header__mobile-top .site-header__mobile-links {
        margin-top: 20px !important;
        margin-right: 0px;
    }
    .site-header__mobile-top .mobile-button-container {
        display: inline-block;
        vertical-align: top;
        margin-top: 20px !important;
        width: 29px;
    }

    #search-visible-overlay {
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 1;
    }

    .search-visible-blur {
        filter: blur(2px);
    }
    .site-header__mobile-top .minisearch {
        position: relative;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .site-header__mobile-top .mobile-header-search-toggle-container .mobile-header-search-toggle:before {
        display: none;
    }
    .mobilenavigation-bar-scrolled .mobile-header-search-toggle-container .mobile-header-search-toggle:before {
        display: block;
    }
    .site-header__mobile-top .minisearch .minisearch__control--submit {
        display: block;
        top: 0;
        right: 0;
        position: absolute;
        height: 30px;
        width: 40px;
    }

    .site-header__mobile-top {
        border-bottom: 1px solid #dddddd;
        padding: 23px 20px 10px 20px;
    }
    .site-header__mobile-top .minisearch {
        margin-bottom: 0px;
    }
    .site-header__mobile-top .minisearch .minisearch__control--text {
        text-align: center;
        height: 32px;
        background: #f5f5f5;
        border: 1px solid #f5f5f5;
        outline: 0;
        font-size: 14px;
    }
    .site-header__mobile-top .minisearch .minisearch__control--text::placeholder {
        text-transform: capitalize;
        color: #9a9a9a;
        font-size: 12px;
    }
    .focused-input .minisearch__control--submit {
        background-color: #dddddd;
    }
    .site-header__mobile-top .site-header__mobile-links li + li {
        padding-left: 8px !important;
    }

    .checkout-cart-index .mobile-header-search-toggle {
        display: inline-block !important;
    }
    .checkout-cart-index .mobile-header-search-toggle:before {
        display: inline-block !important;
    }

    .checkout-cart-index .minisearch__control {
        display: none;
    }
    .site-header__mobile-top .mobile-header-search-toggle-container {
        margin-top: 20px;
        vertical-align: top;
        text-align: left;
    }

    .menu-shown .mobile-header-search-toggle:before {
        display: inline-block !important;
    }
}
