/*------------------------------------*\
    # COMPONENT OVERRIDES
\*------------------------------------*/

/**
 * Add local component overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

// PDP Recently Viewed Override
@media (max-width: 960px) {
  .recently-viewed-pdp-container {
    display: none !important;
  }
}

/* Sort By Options Fixes */
@media (max-width: 959px) {
  .catalog-category-view .toolbar {
    padding: 0 10px;
  }
  .toolbar__sorter {
    width: 65%;
  }
  .toggle-refine-filters-container {
    width: 35%;
  }
  .toggle-refine-filters-container .toggle-refine-filters,
  .toolbar__sorter .sorter-container select {
    font-size: 14px;
  }
  .toolbar__sorter .sorter-container select {
    padding: 0 27px 0 13px;
  }
}

/* Quick View Changes */
.quickview__view-full-details-container .quickview__view-full-details-button {
  color: #000;
  background-color: #f7f7f7;
  border: none;
}

/* MMI-557 */
@media (max-width: 960px) {
  .category-description {
    display: none;
  }
}

/* MMI-626 */
.recently-viewed-pdp-container {
  background-color: #fff;
}

/* MMI-949 */
@media (min-width: 768px) {
  .product-info .badge-container {
    margin-left: 95px;
  }
}

.fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store {
  font-family: futura-pt, sans-serif;
}

@media (max-width: 960px) {
  .popup_content .popup-close button:before {
    font-size: 16px;
  }
}

/* MMI-781 Hide sale badge in sale category*/
.category-sale .product-list__item .product-tile .badge-container {
  display: none;
}

/* MMI-1112 BV position fix */
[data-bv-show="rating_summary"] .bv_main_container {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

/* MMI-1161 Show BV stars on PDP*/
.product-info__main .product-info__brand {
  display: block !important;
}

.product-info__main .product-title__brand {
  display: none !important;
}

[data-bv-show="rating_summary"] .bv_main_container .bv_button_buttonMinimalist {
  display: none !important;
}

[data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
  margin-right: -1em !important;
}

/* MMI-1033 - PDP Size Recommend */
.recommended-size-wrapper .recommended-size-scale {
  display: none;
}

.recommended-size-wrapper .recommended-size-scale,
.recommended-size-wrapper .recommended-size-value {
  font-weight: bold;
}

/* MMI-1084 New Header Design*/
.site-header__banner .stockistsMenuItem {
  display: inline-block;
  text-align: left;
  margin: 0px;
  padding: 7px 20px 7px 0;
}

.site-header__banner .stockistsMenuItem a:before {
  background: url("/media/wysiwyg/Midas/icons/store.png") no-repeat;
  width: 26px;
  height: 26px;
  background-size: contain;
  outline: 0;
  content: "";
  display: block;
}

.site-header__banner .stockistsMenuItem .nav-store-locator-text {
  display: none;
}

.site-header__banner ul.header.links {
  list-style: none;
  display: block;
  margin: 0px;
  padding: 7px 20px 7px 0;
  float: left;
}

.search-suggest-icon {
  outline: 0;
}

.site-header__mobile-top .stockistsMenuItem {
  display: inline-block;
  text-align: left;
  margin-top: 20px !important;
  padding: 0 8px;
}

.site-header__mobile-top .stockistsMenuItem a:before {
  background: url(/media/wysiwyg/Midas/icons/store.png) no-repeat;
  width: 26px;
  height: 26px;
  background-size: contain;
  outline: 0;
  content: "";
  display: block;
}

.site-header__mobile-top .stockistsMenuItem .nav-store-locator-text {
  display: none;
}

.slideout-menu {
  padding-top: 90px;
}

@media (max-width: 960px) {
  .full-site-banner {
    margin-top: 122px;
  }
  .site-header__mobile-links .wishlist .counter,
  .site-header__mobile-links .minicart__summary .minicart__count {
    width: 17px;
    height: 17px;
    font-size: 10px;
    top: 3px;
    right: -5px;
  }
  .mobile-header-search-toggle {
    outline: 0;
  }
  .site-header__mobile-top .site-header__mobile-links li + li {
    padding-left: 15px !important;
  }

  .checkout-cart-index .main-content {
    margin-top: 100px;
  }
}

/* temporary fix removed from main nav static block*/
.hide-menu-item {
  display: none;
}

.navigation-bar-scrolled .hide-on-sticky-nav {
  display: none;
}

/* CSS removed from Sitewide banner static block */

/* Temp fix for layout caching issue */
.cms-page-view .page-layout--two-col .page-layout__item--primary,
.cms-home .page-layout--two-col .page-layout__item--primary {
  width: 100% !important;
}

/* End temp fix */

/* Fix for banner sale issue. */
@media (max-width: 960px) {
  .catalog-product-view .product-info .sale-banner-exists {
    position: relative;
    top: 0 !important;
  }
  .catalog-product-view .product-img-inc-banner {
    margin-top: -100px;
  }
}

/* End Fix */

/* Fix for specific page alignment issues */
.cms-fusion-rewards .full-site-banner .wrapper--gutter,
.cms-privacy-policy .wrapper--gutter,
.contact-index-index .wrapper--gutter {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
  max-width: 82.5rem !important;
  padding-top: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* End Gutter Fix */
/* END CSS removed from Sitewide banner static block */

/* Fix for BIS button to hide initially */
.product-notify-me-wrapper .hidden-notify {
  display: none;
}

/* Fix for BV Star */
.product-info__main .product-info__title {
  margin-top: 0px !important;
  padding-top: 12px !important;
}

/* Fix for sale badge */
.wrapper--gutter {
  position: relative;
}

@media (max-width: 750px) {
  .product-info .badge-container {
    margin-left: 0px !important;
  }
}

@media (max-width: 960px) {
  .product-info .badge-container {
    top: 10px !important;
  }
}

/* MMI-745 Apply Filter when active */
.refine-filters__apply-container .applied {
  background: $color-shop-accent !important;
  color: #fff !important;
}

/* MMI-1348 */
.mobile-refine-controls.fixed {
  top: 90px;
}

/* MMI-1137 */
html,
.category-header .category-description p,
.footer-seo-text .title,
.checkout-index-index .modal-popup.popup-authentication._show .modal-action.remind,
.modal-login-register .modal-action.remind,
.account-nav-content ul,
.product-info .badge-container .badge,
.three-up__description,
#js-aligent_fredhopper-autocomplete-results *,
.filter .filter__category .size-switcher,
.filter .filter__category ol input + label,
.filter .filter__category ol.filter__options a.filter__option,
.checkout-app-form,
.checkout-app-authentication-card-user-login__intro-header,
.checkout-app-authentication-card-user-login__intro-text,
.checkout-app-shopping-bag-item-summary__title {
  font-family: futura-pt, sans-serif !important;
}

.ap-header-section {
  text-align: center;
  margin-top: 0px !important;
}

.four-up-icon-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 60px;
  margin-top: 0px !important;
}

@media (max-width: 470px) {
  .product-info__main {
    padding-top: 10px !important;
  }
  [data-bv-show=rating_summary] .bv_main_container {
    top: -10px !important;
  }
}