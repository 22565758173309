
.topInfoBar {
  a {
    text-decoration: none;
  }
}

.site-header__strip {
  background-color: $color-header-background;
  color: $color-top-info-bar-text;
  border-bottom: solid 2px $color-site-header-border;

  ul {
    @extend %list-bare;
    display: inline-block;
    li {
      display: inline-block;
      padding:7px 0;
    }
  }

  .topInfoBarOuterContainer {
    @include clear();
  }

  .topInfoBarContainer {
    @include small-text( $color-top-info-bar-text );
    padding: 0 20px;
    @media (min-width: 1260px) {
      padding: 0 $spacing-unit-base--small;
    }

    .topInfoBar {
      li {
        @include info-list-item;

        + li {
          padding-left: 10px;
          @media (min-width: 740px) {
            padding-left: 30px;
          }
        }
      }
    }

    ul.header.links {
      margin-left: 20px;
      @media (min-width: 820px) {
        float: right;
        margin-left: 0;
      }

      .authorization-link {
        a {
          text-align: center;
          &:before {
            display: block;

            @media (min-width: 940px) {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.site-header__mobile {

  .topInfoBar {
    background-color: $color-header-background;
    color: $color-top-info-bar-text;
    list-style: none;
    margin: 0;
    font-size: 0;
    display: flex;
    flex-direction: row;

    li {
      @include small-text-black;
      color: $color-top-info-bar-text;
      font-size: 12px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding: 10px;

      &:before {
        @include icon-font;
        font-size: 16px;
        margin:0 10px 0 0;
      }

      @include media-query(small-and-up) {
        padding: 10px 25px;
      }

      .label {
        display: inline-block;
        white-space: normal;
        line-height: 1em;
      }
    }
  }
}
.customer-welcome a,
.authorization-link a {
  text-decoration: none;
}
.customer-welcome .customer-name:before, /* Show the person icon next to the customers name when they're logged in */
.authorization-link a[href*='login']:before { /* Show the person icon next to this link when it's asking a user to log in */
  @include icon-font;
  content: '\e7fd';
  vertical-align: bottom;
}

.authorization-link a[href*='logout'] {
  &:before {
    content: '(';
    display: inline;
  }
  &:after {
    content: ')';
    display: inline;
  }
}
