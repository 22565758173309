.product-notify-me-block-wrapper .product-notify-me-in-stock-info .boldwrapper {
    font-weight: bold;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-note {
    font-weight: 300;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
    text-align: left;
}

@media (max-width: 960px) {
    .product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
        text-align: center;
    }
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-submit,
.product-notify-me-thanks-wrapper .product-notify-me-continue-button {
    background-color: $color-shop-accent;
}

.product-notify-me-block-wrapper .product-notify-me-heading,
.product-notify-me-thanks-wrapper .product-notify-me-heading,
.product-notify-me-block-wrapper .product-notify-me-in-stock-info {
    font-weight: inherit;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email,
.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-submit,
.product-notify-me-block-wrapper .product-notify-me-in-stock-button,
.product-notify-me-thanks-wrapper .product-notify-me-continue-button {
    border-radius: 5px;
}

.product-notify-me-wrapper .product-notify-me-btn {
    border: 1px solid #bcc2cb;
    height: 40px;
    font-size: 16px;
    padding: 8px;
}

.product-notify-me-block-wrapper,
.product-notify-me-thanks-wrapper,
.product-notify-me-wrapper .product-notify-me-btn,
.product-notify-me-block-wrapper .product-notify-me-heading,
.product-notify-me-thanks-wrapper .product-notify-me-heading {
    font-family: futura-pt, sans-serif;
}
