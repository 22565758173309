/*------------------------------------*\
    #MINICART
\*------------------------------------*/

$arrowWidth: 5px;
.up-arrow {
  width: 0;
  height: 0;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;

  border-bottom: $arrowWidth solid #000000;

  position: absolute;
  top: -5px;
  right: 23px;
}

.minicart {
  position: relative;

  & > a:before {
    content: $minicart-icon;
  }

  .minicart__details {
    @extend %u-hidden;
    cursor: default;
    z-index: 5;
    border: 1px solid #000000;
    background-color: #FFFFFF;
    padding: 15px;
    position: absolute;
    min-width: 350px;
    right: 12px;

    &._open {
      @extend %u-display-block;
    }

    .block-title {
      display: none;
    }

    .block-content {
      position: relative;

      .action.close {
        position: absolute;
        top: -7px;
        right: -7px;
      }

      .subtotal {
        text-align: center;
        padding-bottom: 20px;

        span.label {
          display: inline-block;
          vertical-align: middle;
          margin-top: 9px
        }

        .price-container {
          font-size: 22px;
          padding-top: 10px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .items-total {
        display: none;
      }

      .actions {
        .primary {
          .action.primary {
            @include widget-button;
            font-size: 15px;
            padding: 10px;

            display: block;
            margin: 0 auto;
          }
        }
        .secondary .viewcart {
          @include functional-text-black;

          display: block;
          text-align: right;
          margin-top: 20px;
          span {
            font-size: 12px;
          }
        }
      }
    }

    #btn-minicart-close {
      display: none;
    }
  }

  &:hover {
    .minicart__details {
      @extend %u-display-block;
    }
  }

  // Prevent the minicart from showing on the checkout page
  // N.B. the `&:hover` part cannot be nested
  .checkout-cart-index &:hover {
    .minicart__details {
      @extend %u-hidden;
      background-color: green;
    }
  }

  .out-of-stock {
    color: red !important; // Overwriting another style that uses !important
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.9em;
  }
}

.minicart-items { /* ol */
  height: inherit !important; /* Needs to be !important to overcome inline style applied in Magento knockout template */
  margin: 0;
  padding: 10px 0;

  .product-item { /* li */
    display: block;
    padding: 10px;
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border-color: $color-navigation-border;
    }

    .product {
      white-space: nowrap;
    }

    .product-item-photo,
    .product-image-wrapper img { /* Minicart on the checkout doesn't put the .product-item-photo class on the img */
      display: inline-block;
      vertical-align: top;
    }

    .product-item-details {
      display: inline-block;
      position: relative;
      width: calc(100% - 85px);
      margin-left: 10px;
      padding-right: 40px; /*Get the content clear of the delete button icon*/

      .product-item-name {
        white-space: normal;

        a {
          @include functional-text-black;
        }
      }

      .product-item-pricing {
        .price-container > * {
          @include small-text-grey;
        }
      }

      .details-qty {
        &:after {
          display: block;
          clear: both;
        }
        display: flex;
        align-items: center;

        label {
          width: 50%;
          text-align: left;
        }
        input {
          display: inline-block;
          width: 50%;
          text-align: right;
        }

        .value {
          margin-left: 1em;
        }
      }

      .product.actions {
        .action.edit {
          display: none;
        }
        .action.delete {
          position: absolute;
          top: -6px;
          right: 0;

          &:after {
            @include icon-font;
            content: '\e5cd';
            font-size: 20px;
          }

          span {
            display: none;
          }
        }
      }
    }
  }
}

/* This is outside the nesting above so that it will apply the same styling to both dynamic and static cart links */
.minicart__summary {
  position: relative;

  .minicart__count {
    @include counter-number;
    top: 2px;

    &._empty {
      @extend %u-hidden;
    }
  }
}

.minicart-details-header {
  text-align: left;
}

.product-item-details {
  .product.options {
    padding-top: 10px;
    &.list {
      margin: 0;
      display: flex;

      .label {
        width: 50%;
        text-align: left;
        @include small-text-black;
      }

      .values {
        width: 50%;
        text-align: right;
      }
    }
  }
}

.minicart_extra_actions {
  padding-top: 20px;
}

/* Fix minicart count not showing */
.u-visually-hidden._unhide-counter {
  clip: initial!important;
  overflow: initial!important;
  position: absolute!important;
  width: 20px !important;
  height: 20px !important;
}
