.recently-viewed-pdp-container li.product-item {
  margin-right: 5px;
}

/* MMI-1759 PDP Hero image */
.fotorama__wrap .fotorama__stage__frame .fotorama__img {
    max-height: 94.20%;
    max-width: 79.5%;
}

.recently-viewed-pdp-container .block-title {
  display: none;
}