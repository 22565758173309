@use "sass:math";

.siteHeaderBannerContent {
  padding:15px $spacing-unit-base--small;
  line-height: 1em;
}

.site-header__logo-container {
  display: inline-block;
  vertical-align: middle;
  width: 76px;

  @include media-query(medium-and-up) {
    max-width: 303px;
    min-width: 196px;
    width: percentage(math.div(303, 1260));
  }

  img {
    max-width: 196px;
    width: 100%;
    display: block;
  }
}


.account-navigation {
  margin: 0;
  list-style: none;
  max-width:196px;
  width: 100%;
  float:right;
}


.navigation-bar__group--search {
  text-align: center;
  display: inline-block;
  width: 300px;
  margin:0 auto;
  padding:0 0 0 30px;
  position: relative;
  vertical-align: middle;

  @media (min-width: 980px) {
    width: 515px;
  }

  .minisearch__control--text {
    height: 43px;
    /*Width set in media query below*/
    border: 1px solid #000;
    padding: 0 47px 0 20px;
    width:100%;

  }

  .minisearch__control--submit {
    position: absolute;
    right: 2px;
    top: 9px;
  }
}

.site-header__mobile-top {
  @media print {
    display: none;
  }
  @include media-query(medium-and-up) {
    display: none;
  }

  position: fixed;
  padding: 23px 10px 0 10px;
  background-color: #FFFFFF;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #c9c9c9;
  min-width: 264px; /* Stops the icons from wrapping to next line and covering top info links */


  .mobile-button-container {
    display: inline-block;
    vertical-align: middle;
  }

  .site-header__logo-container {
    vertical-align: middle;
    display: inline-block;
    margin-top: -4px !important;
  }

  .mobile-header-search-toggle-container {
    display: inline-block;

    .mobile-header-search-toggle {
      &:before {
        @include icon-font;
        display: block;
        content: '\e8b6';
      }
    }
  }

  .site-header__mobile-links {
    vertical-align: top;
    margin: 0 10px;
    display: inline-block;
    float: right;

    &:after {
      display: block;
      clear: both;
    }

    li + li {
      padding-left: 5px;
      @media (min-width: 333px) {
        padding-left: 20px;
      }

    }

    li {
      list-style: none;
      display: inline-block;
      vertical-align: top;

      a {
        text-decoration: none;
        display: block;

        &:before {
          @include icon-font($font-family-components-header);
          display: block;
        }
      }

      &.wishlist a:before {
        content: $wishlist-icon;
      }

      &.minicart a:before {
        content: $minicart-icon;
      }
    }
  }

  .minisearch {
    width: 100%;
    margin-bottom: 10px;

    overflow-y: hidden;
    max-height: 42px; /* approximate max height */

    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    &.hidden {
      max-height: 0;
    }

    .minisearch__control--text {
      width: 100%;
      padding: 5px 10px;
      height: 42px;
      border: 1px solid #000000;
    }

    .minisearch__control--submit {
      display: none;
    }
  }
}

.site-header__mobile {
  padding-top: 60px;

  @include media-query(medium-and-up) {
    display: none;
  }
}

.site-header__strip,
.site-header__banner {
  display: none;

  @include media-query(medium-and-up) {
    display: block;
  }

}




.minisearch__control--text {
  &::-webkit-input-placeholder { /*Chrome*/
    @include functional-text-black;
  }
  &::-moz-placeholder { /*Firefox 18-*/
    @include functional-text-black;
  }
  &:-moz-placeholder { /*Firefox 19+*/
    @include functional-text-black;
  }
  &:-ms-input-placeholder { /* IE */
    @include functional-text-black;
  }
}

.navigation-bar__group--account {
  li {
    display: inline-block;
    padding-top: 3px; //line up baseline with other header elements

    a {
      @include small-text-grey;
      text-decoration: none;
      display: block;
      line-height: 1em;

      &:hover, &:active, &:focus {
        text-decoration: none;
      }

      &:before {
        @include small-text-black;
        @include icon-font( $font-family-components-header );
        font-size: $font-size-icon-navigation-bar;
        display: block;
        text-align: center;
        margin:0 0 5px;
      }
    }
  }

  .wishlist a:before {
    content: $wishlist-icon;
  }

}

.navigation-bar__group--account > li {
  width:90px;
  text-align: center;

  + li {
    padding-left: 10px;
  }

}

/* MAGENTO-223 */
.navigation-bar-scrolled .site-header__logo-container {
  vertical-align: top;
}