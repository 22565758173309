/*------------------------------------*\
    #PRIMARY NAVIGATION
\*------------------------------------*/
/*ul*/
.primary-navigation {
    $breakpoint: 'medium-and-up';

    @extend %list-bare;
    @extend %u-hidden;  // Hide on small devices
    @extend %u-display-inline-block-#{$breakpoint}; // Display on larger devices

    position: absolute; // Display as drop-down menu on small devices
    width: 100%;

    background-color: $color-navigation-background;
    color: $color-navigation-text;

    @include media-query($breakpoint) {
        position: static;   // Include in the document flow on larger devices
    }

    // Display the list when it is opened
    &._open {
        @extend %u-display-block;
    }


    // Ideally we would use BEM class names to apply styles,
    // but because this is a legacy menu we're stuck with
    // what Magento generates

    a {
        @include aligent-ui-link;

        display: block;
        width: 100%;

        &.level-top {
            @include media-query($breakpoint) {
                border-top: none;
                display: table-cell;
            }
        }
    }

    .level0 {
        &.level-top {
            display: block;

            @include media-query($breakpoint) {
                display: inline-block;
                vertical-align: top;
            }

            & > a {
                position: relative;
                padding: $spacing-unit-base--small;

                &:after {
                    @include icon-font;
                    font-size: 20px;
                    content: '\e313';
                    position: absolute;
                    top: 14px;
                    right: 13px;
                }

                &:only-child:after {
                    margin-left: 0;
                    content: '';
                }

                /* The following is a fix for the spacing between items to have a sub menu and those that don't */
                span {
                    padding-right: 25px;
                }

                &:only-child span {
                    padding-right: 0;
                }
            }

            &:after {
                display: block;
                clear: both;
            }
        }

        &.submenu {
            border: 1px solid #000;
            margin-top: -10px;
        }
    }

    .stockistsMenuItem {
        @include media-query(medium-and-up) {
            float: right;
            position: relative;

            a {
                padding: 12px 0;
                text-align: right;

                &:before {
                    @include icon-font;
                    content: '\e55f';
                    position: absolute;
                    top: 9px;
                    left: $stockist-icon-left-offset;
                }
            }
        }
    }

    /*ul*/
    .submenu {
        @extend %list-bare;

        margin: 0;
        z-index: 10;
        background-color: #fff;

        &.level0 {
            @extend %u-hidden;

            @include media-query($breakpoint) {
                box-shadow: 0 0.25rem 0.5rem rgba(black, 0.2);
                position: absolute;
            }

            &._open {
                @extend %u-display-inline-block;
                @extend %u-hidden-#{$breakpoint};
            }

            // Display the submenu when the top-level menu item is hovered
            @at-root {
                .primary-navigation li.level0:hover .submenu.level0 {
                    @extend %u-display-inline-block-#{$breakpoint};
                }
            }
        }

        &.level1 {
            font-weight: normal;
        }
    }

    /*ul, li*/
    .level1 {
        display: block;
        padding: 5px 13px;

        @include media-query($breakpoint) {
            min-width: 12rem;
        }

        & a {
            color: inherit;
        }
    }

    /*ul, li*/
    .level2 {
        display: block;

        & a {
            padding-left: 2 * $spacing-unit-base--small;

            @include media-query($breakpoint) {
                padding-left: $spacing-unit-base--small;
            }
        }
    }

}
