.navigation-bar-scrolled .search-suggest-icon-wrapper .search-suggest-icon:before {
    background: url("/media/wysiwyg/Midas/icons/search.png") no-repeat;
    width: 26px;
    height: 28px;
    background-size: contain;
    outline: 0;
    content: "";
    display: block;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.minicart {
    border-left: 0px;
    padding-top: 5px;
}
@media (min-width: 960px) {
    .navigation-bar-scrolled .site-header__logo-container img {
        max-height: 28px;
    }
    .navigation-bar-scrolled .fh-search-suggest-wrapper {
        margin-top: 0px;
        float: right;
        margin-top: 5px;
        margin-right: 6px;
        margin-left: 0 !important;
        padding: 0 !important;
        position: relative;
    }
    .navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-search {
        border: 0;
        border-bottom: 1px solid #000000;
        padding: 0 0 1px;
        height: 25px;
        outline: 0;
    }
    .navigation-bar-scrolled .fh-search-suggest-wrapper .minisearch__control--submit {
        display: none;
    }
    .site-header .navigation-bar-scrolled .navigation-bar__wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 78.75rem !important;
        padding: 5px;
        display: flex;
        justify-content: flex-start;
        padding-right: 25px;
    }

    .site-header .navigation-bar-scrolled {
        border-bottom: 1px solid #dddddd;
        padding: 0px !important;
    }
    .navigation-bar-scrolled .site-header__logo-container {
        flex: 30%;
        max-width: 100% !important;
        min-width: 0 !important;
        padding: 10px 0 0 0px;
    }
    .navigation-bar-scrolled #primary-navigation {
        flex: 40%;
        padding-top: 3px;
    }
    .navigation-bar-scrolled .header-icons-wrapper {
        flex: 30%;
        padding-top: 7px;
    }
    .navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.wishlist,
    .navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.minicart {
        margin: 0 !important;
        padding-left: 15px !important;
    }
    .navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.search-suggest-icon-wrapper {
        margin: 0 !important;
    }

    .navigation-bar-scrolled .navigation-bar__group--account .minicart__summary .minicart__count {
        width: 17px;
        height: 17px;
        font-size: 10px;
        right: 0px;
        top: 4px;
    }
    .navigation-bar-scrolled .navigation-bar__group--account .wishlist .counter {
        right: -4px !important;
        width: 17px;
        height: 17px;
        font-size: 10px;
    }
}
@media (max-width: 1020px) {
    .navigation-bar-scrolled .primary-navigation .level0.level-top {
        font-size: 15px !important;
    }
}
@media (max-width: 1220px) {
    .navigation-bar-scrolled .primary-navigation .level0.level-top {
        padding: 0 !important;
    }
}

@media (max-width: 1061px) {
    .navigation-bar-scrolled .fh-search-suggest-wrapper,
    .navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-search {
        width: 150px;
    }
}
@media (min-width: 1062px) {
    .navigation-bar-scrolled .fh-search-suggest-wrapper,
    .navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-search {
        width: 200px;
    }
}
