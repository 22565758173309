@media (min-width: 960px) {
    .navigation-bar__group--account .wishlist .counter {
        right: -4px;
        width: 17px;
        height: 17px;
        font-size: 10px;
        top: 6px;
    }
}
.navigation-bar__group--account .wishlist {
    padding-left: 14px !important;
    width: 40px;
}
