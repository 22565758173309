.customer-welcome .customer-name:before,
.authorization-link a[href*="login"]:before {
    background: url("/media/wysiwyg/Midas/icons/account.png") no-repeat;
    width: 26px;
    height: 26px;
    background-size: contain;
    outline: 0;
    content: "";
    display: block;
}

.authorization-link a[href*="logout"] {
    display: none !important;
}

.site-header__strip {
    background-color: $color-shop-accent;
}