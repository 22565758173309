/*------------------------------------*\
    #CREATE ACCOUNT
\*------------------------------------*/

.form--create-account {
  margin: 0 !important;
}

.create-account {
  .form__input,
  .form__input[type="text"],
  ._has-datepicker,
  input[type="text"] {
    border-radius: 0px !important;
  }

  .form__field--text .form__label,
  .customer-dob-wrapper .label,
  .captcha .label {
    margin-bottom: 2px !important;
    font-weight: 100 !important;
  }
}

.password-length-cond:before,
.password-letters-cond:before,
.password-characters-cond:before {
  padding-right: 5px;
  padding-left: 10px;
}
.create-account {
  .form__input,
  .form__input[type="text"],
  ._has-datepicker,
  input[type="text"] {
    color: black;
  }
}
.create-account {
  .password-validated {
    color: green;
  }
}

.create-account__item--personal-information {
  text-align: left;
}
