.fis-enabled .product-info__main .product-info__social {
    display: flex;
    flex-flow: row wrap;
}

.fis-enabled .product-info__main .product-info__social > * {
    flex: 1 100%;
}

.fis-enabled .product-info__main .product-info__social .product-addto-links {
    margin: 15px 10 10px 0px;
        flex: 1 0 0;
}

.fis-enabled .product-info__main .product-info__social .find-in-store {
    margin: 15px 0px 10px 0;
    flex: 1 0 0;
}
