.site-header__mobile-top .minisearch .minisearch__control--submit:after {
    background: url(/media/wysiwyg/Midas/icons/search.png) no-repeat;
    width: 15px;
    height: 15px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 7px 15px;
}

.site-header__mobile-top .minisearch .search-close-button:before {
    background: url(/media/wysiwyg/Midas/icons/circle-close-icon.png) no-repeat;
    width: 15px;
    height: 15px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    display: block;
    position: absolute;
    top: 0;
    margin: 7px;
}
.site-header__mobile-top .minisearch .search-close {
    position: static;
    right: auto;
    top: auto;
}
.site-header__mobile-top .minisearch .search-close-button {
    top: 0;
    right: 68px;
    position: absolute;
}
@media (max-width: 960px) {
    #js-aligent_fredhopper-autocomplete-results {
        border: 0px solid #ccc;
        top: 112px !important;
    }
}
@media (min-width: 960px) {
    #js-aligent_fredhopper-autocomplete-wrapper {
        width: 200px;
        vertical-align: top;
        padding: 0px;
        margin: 0px;
        display: none;
        float: left;
    }
    #js-aligent_fredhopper-autocomplete-search {
        border-bottom: 1px solid #000 !important;
        padding: 0;
        border: 0;
        outline: 0;
        height: 26px;
    }
    #js-aligent_fredhopper-autocomplete-wrapper .minisearch__control--submit {
        display: none;
    }
    #js-aligent_fredhopper-autocomplete-results {
        right: 0 !important;
        text-transform: capitalize !important;
        color: #000 !important;
    }
    #js-aligent_fredhopper-autocomplete-results * {
        text-transform: capitalize !important;
    }

    #js-aligent_fredhopper-autocomplete-results-1keywords .suggest_keywords .suggest_keyword,
    #js-aligent_fredhopper-autocomplete-results-2categories .suggest-categories .suggest-category {
        display: list-item;
        text-transform: capitalize !important;
        padding: 10px;
        color: #000;
        font-size: 18px;
    }
}
@media (max-width: 960px) {
    #js-aligent_fredhopper-autocomplete-results-1keywords .suggest_keywords .suggest_keyword,
    #js-aligent_fredhopper-autocomplete-results-2categories .suggest-categories .suggest-category {
        padding: 5px 10px;
    }
    #js-aligent_fredhopper-autocomplete-results .suggest_heading {
        margin-bottom: 0.5rem;
    }
}
