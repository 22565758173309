.slideout-menu {
    width: 100%;
}
.primary-navigation-mobile > li:first-child {
    border-top: 0px solid #dddddd;
}
.mobile-navigation-container {
    border: 1px solid #dddddd;
    border-right: 0px;
}
.primary-navigation-mobile li > a {
    font-weight: 100;
    letter-spacing: 1px;
    padding: 0 20px;
}

.primary-navigation-mobile li ul li a {
    padding-left: 0px;
}

.primary-navigation-mobile li > a::after {
    right: 24px;
    top: 19px;
}
.primary-navigation-mobile > li ul li {
    border-bottom: 0px solid #d3d3d3;
    background: #f5f5f5;
}

.primary-navigation-mobile .submenu li > a::after {
    top: 18px;
    padding-left: 20px;
    right: auto;
    font-size: 16px;
}
.primary-navigation-mobile .submenu li > a {
    font-size: 14px;
    height: 40px;
}

.primary-navigation-mobile .parent li > a {
    font-weight: 100;
}
.primary-navigation-mobile > li {
    border-bottom: 1px solid #DDD;
}