@media (max-width: 960px) {
  .product-list__item .product-tile .badge-container {
    left: 4px !important;
  }
}

.product-list__item .product-tile .badge-container {
  left: 20px;
  top: 20px;
  right: auto !important;
}