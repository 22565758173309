.swatch-option.text.selected {
    background-color: $color-shop-accent !important;
}

.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
    color: #ffffff !important;
}

.swatch-option.selected {
    outline: 1px solid #000000 !important;
}